export default theme => ({
  primaryBtn: ({ size }) => {
    if (size === 'medium') {
      return {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      };
    } else if (size === 'small') {
      return {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      };
    } else {
      return {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      };
    }
  },
  secondaryBtn: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    '&:disabled': {
      color: theme.palette.action.disabledBackground,
      backgroundColor: theme.palette.action.disabled,
    },
  },
});
