import { useFormValues } from '../../components/FormBuilder/hooks/useFormValues';
import { SESSION_CONTEXT_TYPE, FORM_CONTEXT_TYPE } from './types';
import { useSession } from 'domains/core/session';
import { makeDomain } from 'store';

/**
 * hookForType returns an array that contains a hook and an optional getter
 *
 * @param {string} type - a type of context, or a context object
 * @return {array} [hook,getter] - hook is the hook to call and the getter, if supplied, gets the appropriate values returned from the hook
 */

export const hookForType = typeOrValues => {
  // the simplest type of context - a plain object
  // the "hook" will just be a function that returns the object
  if (typeof typeOrValues !== 'string') {
    return [() => typeOrValues];
  }

  switch (typeOrValues) {
    case SESSION_CONTEXT_TYPE:
      return [useSession];
    case FORM_CONTEXT_TYPE:
      return [useFormValues];
    // for all other string context types, we'll try and map them to a redux domain
    default:
      return [makeDomain(typeOrValues)];
  }
};
