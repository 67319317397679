import api from './';
const tokenString = token => `Bearer ${token}`;

export const setAPIToken = token =>
  setAPIHeaders({ authorization: tokenString(token) });

export const setAPIHeaders = headers =>
  Object.values(api).forEach(c =>
    Object.entries(headers).forEach(
      ([key, val]) => (c.defaults.headers.common[key] = val)
    )
  );
