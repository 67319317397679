// THIS FILE CANNOT BE gitignore'd BUT ALSO CANNOT BE MODIFIED DURING DEVELOPMENT ¯\_(ツ)_/¯

// this is essentially your ".env.local" file (https://create-react-app.dev/docs/adding-custom-environment-variables/)
// we've eschewed build-time configuration because we'd have to build for each environment which goes against https://12factor.net/ principles (dev/prod parity)
// so we're using "container-up-time" configuration instead of build time
// check the Dockerfile for more information
module.exports = {
  RT_API: 'https://pmvx-dv2.sti.codes',
  RT_S3_ASSETS: 'https://s3.ca-central-1.amazonaws.com/dv.consumer-portal',
  RT_KEYCLOAK_URL: 'https://sso-dv2.sti.codes/auth',
  RT_LOCAL_CONFIG: '',
  RT_ENV: 'dv',
  RT_BUGSNAG: 'fd6605e9569a5e12c964b5ffdfdbf42b',
};
