import { createSelector } from 'reselect';
import DomainName from './name';
import { UNRECOVERABLE, TOAST, REDIRECT, FORM } from './models';

const featureSelector = state => state[DomainName];

export const errors = createSelector(featureSelector, errors => errors);

export const unrecoverableError = createSelector(featureSelector, errors =>
  errors.find(e => e.type === UNRECOVERABLE)
);

export const toastErrors = createSelector(featureSelector, errors =>
  errors.filter(e => e.type === TOAST)
);

export const redirectErrors = createSelector(featureSelector, errors =>
  errors.find(e => e.type === REDIRECT)
);

export const formError = formId =>
  createSelector(featureSelector, errors =>
    errors.find(e => e.type === FORM && e.formId === formId)
  );
