import { parse } from 'querystring';

const SESSION_STORAGE_KEY = 'UTM';
const utm = new RegExp('(utm_.*)', 'i');

export const setUtm = search => {
  if (!search) {
    return;
  }

  const utmParams = Object.entries(parse(search)).reduce((acc, [key, val]) => {
    const utmKey = utm.exec(key);
    return !utmKey ? acc : { ...acc, [utmKey[1]]: val };
  }, {});

  if (Object.keys(utmParams).length) {
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(utmParams));
  }
};

export const getUtm = () =>
  JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY));
