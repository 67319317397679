import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const NoRobots = ({ noIndex }) => {
  if (noIndex) {
    return (
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    );
  }
  return null;
};

NoRobots.propTypes = {
  noIndex: PropTypes.bool,
};

export default NoRobots;
