import { connect } from 'react-redux';
import { compose } from 'recompose';
import ConfiguredRoutes from './ConfiguredRoutes.component';
import { activatedRouteId } from 'domains/core/locales/selectors';
import LocaleActions from 'domains/core/locales/locales.actions';

export const mapStateToProps = state => ({
  activatedRouteId: activatedRouteId(state),
});

const mapDispatchToProps = (dispatch, { activatedRouteId }) => ({
  updateActivatedRouteId: routeId =>
    routeId !== activatedRouteId &&
    dispatch(LocaleActions.updateActivatedRouteId(routeId)),
});

export default compose(
  connect(mapStateToProps),
  connect(null, mapDispatchToProps)
)(ConfiguredRoutes);
