import { combineReducers } from 'redux';
import LocalesActions from './locales.actions';

const initialState = {
  selectedLocale: null,
  activatedRouteId: null,
  dynamicMessages: {},
};

const selectedLocale = (state = initialState.selectedLocale, action) => {
  switch (action.type) {
    case LocalesActions.SELECT_LOCALE:
      return action.selectedLocale;
    default:
      return state;
  }
};

const activatedRouteId = (state = initialState.activatedRouteId, action) => {
  switch (action.type) {
    case LocalesActions.UPDATE_ACTIVATED_ROUTE_ID:
      return action.routeId;
    default:
      return state;
  }
};

const dynamicMessages = (state = initialState.dynamicMessages, action) => {
  switch (action.type) {
    case LocalesActions.LOAD_DYNAMIC_MESSAGES:
      return {
        ...state,
        [action.payload.key]: action.payload.messages,
      };
    default:
      return state;
  }
};

export default combineReducers({
  selectedLocale,
  activatedRouteId,
  dynamicMessages,
});
