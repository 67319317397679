import { useSelector, useDispatch } from 'react-redux';
import { sessionTimeout } from '../config/selectors';
import { sessionData, sessionItem } from './selectors';
import Actions from './actions';

export const useSession = key =>
  useSelector(!key ? sessionData : sessionItem(key));

export const useExtendSession = () => {
  const dispatch = useDispatch();
  return () => dispatch(Actions.extend());
};

export const useInitSession = () => {
  const dispatch = useDispatch();
  const timeout = useSelector(sessionTimeout);
  dispatch(Actions.init(timeout));
};

export const useSetSessionItem = key => {
  const dispatch = useDispatch();
  return (...args) =>
    dispatch(Actions.setItem(key || args[0], key ? args[0] : args[1]));
};

export const useRemoveSessionItem = key => {
  const dispatch = useDispatch();
  return (...args) => dispatch(Actions.removeItem(key || args[0]));
};

export const useClearSession = () => {
  const dispatch = useDispatch();
  return () => dispatch(Actions.clear());
};
