import { createSelector } from 'reselect';
import DomainName from './name';
import merge from 'utils/merge.util';

const featureSelector = state => state[DomainName];

const mergeOverrides = consumer => {
  const host = window.location.hostname.replace(/.+\/\/|www.|\..+/g, '');
  const overrides = (consumer.overrides || {})[host];
  return overrides ? merge(consumer, overrides) : consumer;
};

export const config = createSelector(featureSelector, state => state.config);

export const programId = createSelector(
  config,
  config => config && config.programId
);

export const schedulerConfiguration = createSelector(
  config,
  config => config && config.scheduler
);

export const consumerConfiguration = createSelector(
  config,
  config => config && mergeOverrides(config.consumer)
);

const localesConfiguration = createSelector(
  consumerConfiguration,
  config => config && config.localesConfiguration
);

export const placeholders = createSelector(
  localesConfiguration,
  config => config && config.placeholders
);

export const forceLocale = createSelector(
  localesConfiguration,
  config => config && config.forceLocale
);

export const noIndex = createSelector(
  consumerConfiguration,
  config => config && config.noIndex
);

export const metaTags = createSelector(
  consumerConfiguration,
  config => config && config.metaTags
);

export const localeMessages = createSelector(
  localesConfiguration,
  localesConfig => localesConfig && localesConfig.messages
);

export const defaultLocale = createSelector(
  localesConfiguration,
  config => config && config.defaultLocale
);

export const supportedLanguages = createSelector(
  localesConfiguration,
  localesConfig => localesConfig && localesConfig.supportedLanguages
);

export const mainConfiguration = createSelector(
  consumerConfiguration,
  config => config && config.main
);

export const routesConfiguration = createSelector(
  consumerConfiguration,
  config => config && config.routes
);

export const defaultRoute = createSelector(
  routesConfiguration,
  config => config && config['default']
);

export const routePaths = createSelector(
  routesConfiguration,
  config => config && config.paths
);

export const themeConfiguration = createSelector(
  consumerConfiguration,
  config => config && config.theme
);

export const faviconConfiguration = createSelector(
  consumerConfiguration,
  config => config && config.favicon
);

export const scriptConfiguration = createSelector(
  consumerConfiguration,
  config => config && config.scripts
);

export const keycloakConfiguration = createSelector(
  consumerConfiguration,
  config => config && config.keycloak
);

export const componentsConfiguration = createSelector(
  consumerConfiguration,
  config => config && config.components
);

export const loading = createSelector(featureSelector, state => state.fetching);

export const fieldsConfiguration = createSelector(
  config,
  config => config && config.fields
);

const sessionConfiguration = createSelector(
  consumerConfiguration,
  config => config && config.session
);

export const sessionTimeout = createSelector(
  sessionConfiguration,
  config => config && config.timeout
);

export const getFormDescriptors = fields =>
  createSelector(
    fieldsConfiguration,
    fieldConfig =>
      fieldConfig &&
      fields.reduce((acc, cur) => [...acc, makeField(cur, fieldConfig)], [])
  );

const makeField = (field, fields) => {
  const fieldId = typeof field === 'string' ? field : field.fieldId;
  if (fieldId && !fields[fieldId]) {
    throw new Error(`Field ${fieldId} does not exist`);
  }
  const parent = (fieldId && fields[fieldId]) || {};
  // extend validations and conditions to append app-level configuration
  // of fields to program-level configuration of fields
  const conditions = [
    ...(parent.conditions || []),
    ...(field.conditions || []),
  ];
  const validations = {
    ...(parent.validations || {}),
    ...(field.validations || {}),
  };
  return {
    ...parent,
    ...(typeof field === 'object' && field),
    ...validations,
    conditions,
  };
};
