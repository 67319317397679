import { combineReducers } from 'redux';
import Actions from './actions';

const initialState = {
  fetching: false,
  config: null,
};

const fetching = (state = initialState.fetching, action) => {
  switch (action.type) {
    case Actions.GET:
      return true;
    case Actions.SET:
      return false;
    default:
      return state;
  }
};

const config = (state = initialState.config, action) => {
  switch (action.type) {
    case Actions.SET:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  fetching,
  config,
});
