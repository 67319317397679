import PropTypes from 'prop-types';

export default {
  color: PropTypes.oneOf(['primary', 'secondary', 'default', 'inherit']),
  isPrimary: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
};
