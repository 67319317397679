import { connect } from 'react-redux';
import * as selectors from '../../selectors';
import ErrorHandler from './ErrorHandler.component';

// Register error domain
import * as error from 'domains/core/error';

const mapStateToProps = state => ({
  unrecoverable: selectors.unrecoverableError(state),
  errors: selectors.toastErrors(state),
  redirect: selectors.redirectErrors(state),
});

const mapDispatchToProps = dispatch => ({
  dismiss: e => dispatch(error.Actions.dismiss(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler);
