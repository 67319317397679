import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie11';
import './utils/bugsnag';
import './utils/gtm';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import css from './indexcss';
import Core from 'domains/core';
import { store } from 'store';
import CSS from 'components/CSS';
import { setUtm } from 'utils/utm';

setUtm(window.location.search);

ReactDOM.render(
  <Provider store={store}>
    <CSS content={css} />
    <CssBaseline />
    <Core />
  </Provider>,
  document.getElementById('root')
);
