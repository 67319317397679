import { unflatten } from 'flat';

/**
 * to help map complex payloads our fields configuration have
 * have a "path" property which can look like:
 * {
 *  "name": "uci",
 *  "path": "benefit.card.uci"
 * }
 *
 * The `values` from a form will always be flat and be keyed by the "name" of the field like:
 * {
 *  "uci": "DOVATRKNYQ985MB"
 * }
 *
 * Combined with `unflatten` from flat (https://www.npmjs.com/package/flat) we can use
 * the path to map the form values to the correctly formatted payload
 *
 * NOTE:
 * If a field configuration DOES NOT CONTAIN A PATH the form value WILL NOT BE INCLUDED in the return from this function
 *
 *
 * Fields can also have meta to assist in further building complex payloads.
 * Meta is an array of paths and values to include. e.g:
 *
 * {
 *  "name": "DOVATO_TERMS_AND_CONDITIONS_V1",
 *  "path": "enrollmentOptions.0.value",
 *  "metaData": [
 *    {
 *      "path":"enrollmentOptions.0.name",
 *      "value":"DOVATO_TERMS_AND_CONDITIONS_V1"
 *    }
 *  ]
 * }
 *
 * NOTE: When dealing with metaData - the value of the form field will be assigned to the path of the field itself
 * and the metaData will just be appended to the payload
 *
 */

const mapMetaData = metaData =>
  !metaData
    ? {}
    : (Array.isArray(metaData) ? metaData : [metaData]).reduce(
        (acc, { path, value }) => ({
          ...acc,
          [path]: value,
        }),
        {}
      );

export const mapValuesAndFields = (values, fields) =>
  unflatten(
    Object.entries(fields).reduce(
      (acc, [fieldName, { path, metaData }]) =>
        !path
          ? acc
          : { ...acc, ...mapMetaData(metaData), [path]: values[fieldName] },
      {}
    )
  );
