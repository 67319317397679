import PropTypes from 'prop-types';

export const UNRECOVERABLE = 'UNRECOVERABLE';
export const TOAST = 'TOAST';
export const REDIRECT = 'REDIRECT';
export const FORM = 'FORM';

export const ErrorModel = PropTypes.shape({
  type: PropTypes.oneOf([UNRECOVERABLE, TOAST, REDIRECT]),
});

export const ToastErrorModel = PropTypes.shape({
  message: PropTypes.string,
  type: PropTypes.oneOf([TOAST]),
});

export const UnrecoverableErrorModel = PropTypes.shape({
  type: PropTypes.oneOf([UNRECOVERABLE]),
});

export const RedirectErrorModel = PropTypes.shape({
  routeId: PropTypes.string.isRequired,
  type: PropTypes.oneOf([REDIRECT]),
});
