import Domain from './name';
import reducer from './reducer';
import { ConfigProvider, Main } from './components';
import * as selectors from './selectors';
import * as models from './models';
import * as hooks from './hooks';
import Actions from './actions';

import { reducerRegistry } from 'store';
reducerRegistry.register(Domain, reducer, { preventClear: true });

// public api
export { ConfigProvider, Main, selectors, models, Actions, hooks };
