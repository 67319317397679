import Actions from './actions';
import { UNRECOVERABLE, TOAST, REDIRECT, FORM } from './models';

const initialState = [];

const errors = (state = initialState, action) => {
  switch (action.type) {
    case Actions.ADD: {
      return [
        ...state,
        {
          error: action.error,
          type: TOAST,
        },
      ];
    }

    case Actions.FORM: {
      // there should only be one formError per formId
      // only keep the latest error for each formId
      return [
        ...state.filter(el => el.formId !== action.formId),
        {
          ...action,
          type: FORM,
        },
      ];
    }

    case Actions.REDIRECT: {
      return [
        ...state,
        {
          routeId: action.routeId,
          type: REDIRECT,
        },
      ];
    }
    case Actions.UNRECOVERABLE: {
      return [
        ...state,
        {
          error: action.error,
          type: UNRECOVERABLE,
        },
      ];
    }
    case Actions.DISMISS:
      return state.filter(err => err !== action.payload);
    default:
      return state;
  }
};

export default errors;
