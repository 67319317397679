import PropTypes from 'prop-types';

export const children = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

const oneToTwelve = PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
const gridShape = {
  lg: oneToTwelve,
  md: oneToTwelve,
  sm: oneToTwelve,
  spacing: PropTypes.number,
  xl: oneToTwelve,
  xs: oneToTwelve,
};

gridShape.container = PropTypes.shape(gridShape);

export const grid = PropTypes.shape(gridShape);
