import env from 'env';

const runtime =
  process.env.NODE_ENV !== 'production'
    ? env
    : JSON.parse(document.getElementById('runtime-config').innerHTML);

if (!runtime) {
  throw new Error(`No runtime configuration found!`);
}

process.env.NODE_ENV === 'production' &&
  Object.keys(env).forEach(k => {
    if (typeof runtime[k] === 'undefined') {
      throw new Error(`missing env ${k}`);
    }
  });

export default runtime;
