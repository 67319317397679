import merge from 'deepmerge';

// overwrite arrays, don't concat them - https://github.com/TehShrike/deepmerge#overwrite-array
const arrayMerge = (_, source) => source;

export default (...args) =>
  merge.all(
    args.filter(a => !!a),
    { arrayMerge }
  );

export const configMergeOptions = {
  customMerge: key => {
    if (key !== 'supportedLanguages') {
      return merge;
    } else {
      return arrayMerge;
    }
  },
};
