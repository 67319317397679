import Bugsnag from '@bugsnag/js';
import env from 'runtime.env';

Bugsnag.start({
  apiKey: env.RT_BUGSNAG,
  releaseStage: env.RT_ENV,
  enabledReleaseStages: ['pd', 'sg', 'qa'],
  appType: 'consumer-portal-client',
  logger: null,
});
