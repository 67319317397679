import env from 'runtime.env';

const gtm = document.createElement('script');
gtm.src = `${env.RT_API}/config/api/configurations/gtms/gtm.js`;

gtm.onload = () => {
  (function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', window.gtmId);
};

document.head.appendChild(gtm);
