import config from './config.api';
import patient from './patient.api';
import document from './document.api';
import references from './references.api';

const api = {
  config,
  patient,
  document,
  references,
};

export { config, patient, document, references };

export default api;
