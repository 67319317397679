import { matches, createSchemas } from '@sti/validation-schema';
import { LIKE, NOTLIKE, REFERER, DIRECT, SESSION } from '../../../models';

export const checkRefererGuard = (guard, history = []) => {
  const last = history[history.length - 1];
  if (!last) {
    return false;
  }

  const match = guard.match.match(last.pathname);

  switch (guard.how) {
    case NOTLIKE:
      return match;
    case LIKE:
    default:
      return !match;
  }
};

export const checkSessionGuard = ({ when, how }, session) =>
  matches({ schemas: createSchemas(when), how }, session);

export const checkDirectGuard = history => history.length > 0;

export const checkGuard = (guard, history = [], session) =>
  (guard || []).every(_guard => {
    switch (_guard.type) {
      case REFERER:
        return checkRefererGuard(_guard, history);

      case DIRECT:
        return checkDirectGuard(history);

      case SESSION:
        return checkSessionGuard(_guard, session);

      default:
        return true;
    }
  });
