import Bugsnag from '@bugsnag/js';
import { get } from 'lodash';

export const logApiError = (err, reason) =>
  Bugsnag.notify(err, e =>
    e.addMetadata('details', {
      reason,
      apiMessage: get(err, 'response.data.message'),
    })
  );
