import flatten from 'flat';
import { hookForType } from './hookForContextType';
const DEFAULT_OBJECT = {};

// FormattedMessage from react-intl cannot handle '.' in values.
// When flattening an object, convert all '.' present in string keys to _
const convertKeys = (what, to = '_') => {
  if (
    !what ||
    typeof what === 'string' ||
    typeof what === 'number' ||
    typeof what === 'boolean' ||
    Array.isArray(what)
  ) {
    return what;
  }

  return Object.entries(what).reduce((acc, [key, value]) => {
    key = typeof key === 'string' ? key.replace(/\./g, to) : key;
    return {
      ...acc,
      [key]: convertKeys(value),
    };
  }, {});
};

/**
 * useContexts takes a string, an object, or an array of strings/objects that provide values via
 * mapping to hooks or redux domains, or the values store in the object itself
 * It returns an object that is one level deep consisting of all the values returned from the hook(s)
 * Example: useContexts(['LOCALES']) would map to a redux domain and could return something like this:
 * {
 *  LOCALES_selectedLocale: 'en',
 *  LOCALES_activatedRouteId: '/'
 *  LOCALES_additionalMessages_FORM_ERRORS_form_error_required_en: "{label} is required"
 *  LOCALES_additionalMessages_FORM_ERRORS_form_error_required_fr: "[FR] {label} is required"
 * }
 *
 * Pass a contextMap to "nicen" the flattened object
 * Example: useContexts(
 *  ['LOCALES','ENROLMENT'],
 *  {
 *    selectedLocale: 'LOCALES_selectedLocale'
 *    patientProvinceCode: 'ENROLMENT_enrollment_patient_province_code
 *  }
 * )
 *
 *
 *
 *
 * @param {string | array} types
 * @param {object} contextMap
 * @return {object}
 */

export const useContexts = (types, contextMap) => {
  const values = (Array.isArray(types) ? types : [types]).reduce(
    (carry, nameOrObject) => {
      const [hook, getHookValues] = hookForType(nameOrObject);
      const hookValues = hook();
      return typeof nameOrObject === 'string'
        ? {
            ...carry,
            [nameOrObject]: getHookValues
              ? getHookValues(hookValues)
              : hookValues,
          }
        : {
            ...carry,
            ...hookValues,
          };
    },
    DEFAULT_OBJECT
  );

  const flattened = flatten(convertKeys(values), { delimiter: '_' });
  return contextMap
    ? Object.entries(contextMap).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: flattened[value],
        }),
        {}
      )
    : flattened;
};
