import { useState, useLayoutEffect } from 'react';
import { useConfiguredFormContext } from '../contexts';

export const useObservable = (observable, init = undefined) => {
  const [value, setValue] = useState(init);

  useLayoutEffect(() => {
    const subscription = observable.subscribe(setValue);
    return () => subscription.unsubscribe();
  }, [observable]);

  return value;
};

export const useFormBuilderObservableProp = (prop, init) => {
  const { fb } = useConfiguredFormContext();
  if (!fb[prop]) {
    throw new Error(`${prop} doesn't exist on FormBuilder`);
  }
  const [observable$, setObservable$] = useState(fb[prop]);

  useLayoutEffect(() => {
    setObservable$(fb[prop]);
  }, [fb, prop]);

  return useObservable(observable$, init);
};
