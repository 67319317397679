import React, { lazy, memo, Suspense } from 'react';
import PropTypes from 'prop-types';
import ConfiguredComponent from './ConfiguredComponent.component';
import makeConfiguration from './makeConfiguration';
import { importComponent } from './importComponent';

export const loader = components => idOrDescriptor => {
  const descriptor =
    typeof idOrDescriptor === 'string'
      ? components[idOrDescriptor]
      : idOrDescriptor;
  if (!descriptor) {
    throw new Error(`Unable to load component ${idOrDescriptor}`);
  }

  return (
    <ConfiguredComponent
      key={JSON.stringify(descriptor)}
      Component={lazy(importComponent(descriptor))}
      loader={loader(components)}
      useConfiguration={makeConfiguration(descriptor)}
    />
  );
};

/*

VERY IMPORTANT
==============

1.  ConfiguredComponentLoader is the start of a dynamic loaded tree which may suspend while rendering.
    For the time being, instead of letting the user define their own Suspense error boundary
    we'll assume it's at the top of the dynamic tree. If the user has defined their Suspense too high
    then it can cause much more rerendering then necessary. We may decide later we want to create
    a nicer DynamicTreeLoader component that accepts loader props for stylistic purposes.


2.  This component, as it stands right now, is memo-ed for super fast performance. Extra props might sometimes be 
    passed to it accidentally (when rendering it from a router, for instance). Because this can destroy performance
    we're going to throw an error when not in pd

Footnote:
I spent about one month learning a very hard (stupid, in hindsight, as usual) lesson from number 1: 
As given by https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries

```
 ...catch JavaScript errors anywhere in their child component tree, log those errors, and display a fallback UI... 
```

Spelling it out very clearly: when a component suspends while rendering, EVERYTHING below the nearest 
ErrorBoundary (Suspense) is UNMOUNTED AND REPLACED with the fallback UI given to Suspense
    
*/
const ConfiguredComponentLoader = memo(({ components, id, ...rest }) => {
  if (rest.length && process.env.NODE_ENV !== 'production') {
    throw new Error(
      `ConfiguredComponentLoader ${id} started to be rendered with unintended props`
    );
  }
  return <Suspense fallback={<></>}>{loader(components)(id)}</Suspense>;
});

ConfiguredComponentLoader.propTypes = {
  components: PropTypes.object,
  id: PropTypes.string,
};

export default ConfiguredComponentLoader;
