const API_FAILURE = 'api_error_failure';
const UCI_INVALID = 'api_error_patient_uciInvalid';
const ENROLLMENT_EXISTS_FOR_CLIENTID = 'api_error_patient_enrollmentExists';
const ENROLLMENT_FAILED = 'api_error_patient_enrollmentFailed';
const ENROLLMENT_EXISTS_FOR_PATIENT = 'api_error_patient_alreadyEnrolled';

export default {
  API_FAILURE,
  UCI_INVALID,
  ENROLLMENT_EXISTS_FOR_CLIENTID,
  ENROLLMENT_FAILED,
  ENROLLMENT_EXISTS_FOR_PATIENT,
};

export const createAPIError = code => {
  const err = new Error(code);
  err.isAPIError = true;
  return err;
};

export const isAPIError = err => err.isAPIError;

export const defaultAPIError = (err, defaultError = API_FAILURE) => {
  throw isAPIError(err) ? err : createAPIError(defaultError);
};
