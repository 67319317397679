import { createSelector } from 'reselect';
import DomainName from './name';

const featureSelector = state => state[DomainName];

export const expires = createSelector(
  featureSelector,
  state => state && state.expires
);

export const sessionData = createSelector(
  featureSelector,
  state => state && state.data
);

export const sessionItem = key =>
  createSelector(sessionData, state => state && state[key]);
