import axios from 'axios';
import { createAPIService } from './api.service';
import errors, { defaultAPIError } from './errors';
import env from 'runtime.env';
import { logApiError } from 'utils/bugsnagHelper';
import { convertConsumerConfig } from 'utils/api.util';

export const CONSUMER_CONFIGURATION_ENDPOINT =
  '/configurations/programs/consumer';

export const APPLICATION_CONFIGURATION_ENDPOINT =
  '/configurations/application-configurations';
export const UI_ENDPOINT = programId =>
  `/configurations/programs/${programId}/uis/consumer`;

export const LOOKUP_ENDPOINT = id => `/configurations/lookups/${id}/execute`;

const service = createAPIService('/config/api');

// endpoints
service.getProgramConfiguration = async (params = {}) => {
  try {
    if (params.programId) {
      const response = await service.get(UI_ENDPOINT(params.programId));
      return response.data;
    }
    const response = await service.get(CONSUMER_CONFIGURATION_ENDPOINT);
    return response.data;
  } catch (err) {
    logApiError(err, 'failed program configuration load');
    throw defaultAPIError(err, errors.API_FAILURE);
  }
};

service.getApplicationConfiguration = async () => {
  if (env.RT_LOCAL_CONFIG) {
    /* eslint-disable-next-line no-console */
    console.warn('Config loading from local resources');
    const response = await axios.get(env.RT_LOCAL_CONFIG, {
      baseURL: '/',
    });
    if (response.data.base) {
      return response.data.base;
    } else if (response.data.uis) {
      return convertConsumerConfig(response.data);
    } else {
      return response.data;
    }
  }
  try {
    const response = await service.get(APPLICATION_CONFIGURATION_ENDPOINT, {});
    return response.data.config;
  } catch (err) {
    logApiError(err, 'failed application configuration load');
    throw defaultAPIError(err, errors.API_FAILURE);
  }
};

service.lookup = async id => {
  const response = await service.get(LOOKUP_ENDPOINT(id));
  return response.data;
};

export default service;
