import PropTypes from 'prop-types';

export default {
  realm: PropTypes.number,
  clientId: PropTypes.string,
  url: PropTypes.string,
  init: PropTypes.shape({
    adapter: PropTypes.oneOf(['cordova', 'cordova-native', 'default']),
    checkLoginIframe: PropTypes.bool,
    checkLoginIframeInterval: PropTypes.number,
    flow: PropTypes.oneOf(['standard', 'implicit', 'hybrid']),
    idToken: PropTypes.string,
    promiseType: PropTypes.oneOf(['native']),
    redirectUri: PropTypes.string,
    refreshToken: PropTypes.string,
    responseMode: PropTypes.oneOf(['query', 'fragment']),
    timeSkew: PropTypes.number,
    token: PropTypes.string,
    onLoad: PropTypes.oneOf(['login-required', 'check-sso']),
  }),
};
