import React from 'react';
import PropTypes from 'prop-types';
import { useSetSessionItem } from '../hooks';

// set session data on click of a child element
const SetSessionData = ({ itemKey, data, element, children }) => {
  const fn = useSetSessionItem(itemKey);
  return React.createElement(
    element,
    { onClick: () => fn(data) },
    ...(Array.isArray(children) ? children : [children])
  );
};

SetSessionData.propTypes = {
  element: PropTypes.oneOf(['span', 'div']).isRequired,
  itemKey: PropTypes.string.isRequired,
  data: PropTypes.any,
};

SetSessionData.defaultProps = {
  element: 'div',
};

export default SetSessionData;
