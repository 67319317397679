import React from 'react';
import PropTypes from 'prop-types';
import { useClearSession } from '../hooks';

// clears session data on click of a child element
const ClearSessionData = ({ element, children }) => {
  const onClick = useClearSession();
  return React.createElement(
    element,
    { onClick },
    ...(Array.isArray(children) ? children : [children])
  );
};

ClearSessionData.propTypes = {
  element: PropTypes.oneOf(['span', 'div']).isRequired,
};

ClearSessionData.defaultProps = {
  element: 'div',
};

export default ClearSessionData;
