import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Switch, Redirect, Route } from 'react-router-dom';
import ConfiguredRoute from './ConfiguredRoute';
import { routesFromPaths } from './utils';
import history from 'utils/history';

class ConfiguredRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error:
        Object.keys(props.routes.paths).length === 1
          ? new Error('No Route Provided')
          : null,
    };
  }

  componentDidCatch(error) {
    this.setState({ error: error });
  }

  render() {
    const {
      unrecoverable,
      intl,
      activatedRouteId,
      updateActivatedRouteId,
    } = this.props;
    const { error } = this.state;
    const { default: defaultRoute, paths } = this.props.routes;

    const routes = routesFromPaths(
      paths,
      intl,
      activatedRouteId,
      history.location.pathname
    );

    return (
      <Switch>
        {this.state.error ? (
          <ConfiguredRoute
            {...routes[0]}
            updateActivatedRouteId={updateActivatedRouteId}
            key={routes[0].path}
          />
        ) : (
          routes.map((descriptor, idx) => (
            <ConfiguredRoute
              {...descriptor}
              key={idx}
              updateActivatedRouteId={updateActivatedRouteId}
            />
          ))
        )}
        <Route
          render={() => {
            if (error || !defaultRoute) {
              unrecoverable(error);
              return null;
            }
            return <Redirect to={{ pathname: defaultRoute }} />;
          }}
        />
      </Switch>
    );
  }
}

ConfiguredRoutes.propTypes = {
  intl: PropTypes.object.isRequired,
  routes: PropTypes.object.isRequired,
  unrecoverable: PropTypes.func.isRequired,
  activatedRouteId: PropTypes.string,
  updateActivatedRouteId: PropTypes.func,
};

export default injectIntl(ConfiguredRoutes);
