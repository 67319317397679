import { useEffect } from 'react';

const useRemoteScripts = scripts => {
  useEffect(() => {
    scripts.map(el => {
      const script = document.createElement('script');
      script.src = el.src;
      script.async = el.async;
      document.head.appendChild(script);
      return null;
    });
    return () => {
      scripts.map(el => document.head.removeChild(el));
    };
  }, [scripts]);
};

export default useRemoteScripts;
