import React, { Suspense } from 'react';
import model, { SHOW, HIDE } from './ContextConditional.model';
import { useContextCondition } from 'utils/useContext';

const FRAG = <></>;

const ContextCondition = ({ then = SHOW, children, ...rest }) => {
  const matches = useContextCondition(rest);

  // why suspense here?
  // if a configured component (for instance) is conditionally hidden
  // and then something changes to show it, the tree will
  // unmount all the way back up to the root Suspense
  // if something below this suspends.
  // that's not desirable...

  return (then === SHOW && matches) || (then === HIDE && !matches) ? (
    <Suspense fallback={FRAG}>{children}</Suspense>
  ) : null;
};

ContextCondition.propTypes = model;

export default ContextCondition;
