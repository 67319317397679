import { useDispatch, useSelector } from 'react-redux';
import { dynamicMessagesByKey } from './selectors';
import Actions from './locales.actions';

export const useDynamicMessages = (key, messages) => {
  const dispatch = useDispatch();
  const existing = useSelector(dynamicMessagesByKey(key));
  if (!existing && messages) {
    dispatch(Actions.loadDynamicMessages(key, messages));
  }
};
