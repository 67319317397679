import React from 'react';

// register core config domain
import * as config from 'domains/core/config';

const { ConfigProvider, Main } = config;

const Core = () => {
  return (
    <ConfigProvider>
      <Main />
    </ConfigProvider>
  );
};

export default Core;
