import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import { Router } from 'react-router-dom';
import { ThemeModel } from '../../models';
import ConfiguredRoutes from '../ConfiguredRoutes';
import ConfiguredComponentLoader from '../ConfiguredComponentLoader';
import Helmet from 'components/Helmet';
import history from 'utils/history';
import ScrollToTop from 'utils/ScrollToTop';
import merge from 'utils/merge.util';
import ErrorHandler from 'domains/core/error/components/ErrorHandler';
import { useInitSession } from 'domains/core/session/hooks';
import LocalesProvider from 'domains/core/locales/components/LocalesProvider';
import NoRobots from 'components/NoRobots';
import Keycloak from 'containers/Keycloak';
import MetaTagInjector from 'components/MetaTagInjector/MetaTagInjector';

const renderContent = (
  favicon,
  scripts,
  keycloak,
  main,
  noIndex,
  metaTags,
  routesConfig
) => (
  <>
    {(favicon || scripts) && <Helmet {...favicon} scripts={scripts} />}
    <NoRobots noIndex={noIndex} />
    <MetaTagInjector metaTags={metaTags} />
    <Keycloak {...keycloak}>
      <LocalesProvider>
        <Router history={history}>
          <ErrorHandler>
            <ScrollToTop>
              {main ? (
                <ConfiguredComponentLoader id={main} />
              ) : (
                <ConfiguredRoutes {...routesConfig} />
              )}
            </ScrollToTop>
          </ErrorHandler>
        </Router>
      </LocalesProvider>
    </Keycloak>
  </>
);

const Main = ({
  theme,
  favicon,
  scripts,
  keycloak,
  main,
  noIndex,
  metaTags,
  ...routesConfig
}) => {
  useInitSession();
  return theme ? (
    <ThemeProvider theme={outerTheme => merge(outerTheme, theme)}>
      {renderContent(
        favicon,
        scripts,
        keycloak,
        main,
        noIndex,
        metaTags,
        routesConfig
      )}
    </ThemeProvider>
  ) : (
    renderContent(
      favicon,
      scripts,
      keycloak,
      main,
      noIndex,
      metaTags,
      routesConfig
    )
  );
};

Main.propTypes = {
  routes: PropTypes.object.isRequired,
  unrecoverable: PropTypes.func.isRequired,
  favicon: PropTypes.object,
  keycloak: PropTypes.object,
  main: PropTypes.string,
  metaTags: PropTypes.array,
  noIndex: PropTypes.bool,
  scripts: PropTypes.array,
  theme: ThemeModel,
};

Main.defaultProps = {
  favicon: undefined,
  theme: undefined,
  scripts: [],
  routes: [],
};

export default Main;
