import PropTypes from 'prop-types';
import { children } from 'common/models';

export const SHOW = 'show';
export const HIDE = 'hide';

export default {
  when: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  context: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  contextMap: PropTypes.object,
  then: PropTypes.oneOf([SHOW, HIDE]),
  children,
};
