import { get } from 'lodash';
import { saveAs } from 'file-saver';
import { createAPIError, defaultAPIError } from './errors';
import { createAPIService } from './api.service';

const service = createAPIService('/document/api/');

service.downloadSurvey = async v => {
  const { surveyTemplateId, ...rest } = v;

  try {
    const response = await service.get(surveyTemplateId, {
      responseType: 'blob',
      params: rest,
    });

    const filename = (response.headers['content-disposition'] || '').match(
      /filename="(.*)"/
    );

    const saveAsFilename =
      (filename && filename.length && filename[1]) || 'survey.pdf';

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(response.data, saveAsFilename);
    } else {
      saveAs(response.data, saveAsFilename);
    }
    return true;
  } catch (err) {
    switch (get(err, 'response.status', err.code)) {
      case 404:
      case 403:
        throw createAPIError(err);
      default:
        throw defaultAPIError(err);
    }
  }
};

export default service;
