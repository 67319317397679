import React, { useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import { PropTypes } from 'prop-types';
import { Redirect } from 'react-router-dom';
import ErrorToast from '../ErrorToast';
import {
  UnrecoverableErrorModel,
  ToastErrorModel,
  RedirectErrorModel,
} from '../../models';

const log = errors =>
  errors && errors.forEach(e => e && e.message && Bugsnag.notify(e.message));

const ErrorHandler = ({
  unrecoverable,
  redirect,
  errors,
  children,
  dismiss,
}) => {
  useEffect(() => {
    if (unrecoverable) {
      dismiss(unrecoverable);
    }
    if (redirect) {
      dismiss(redirect);
    }
  }, [unrecoverable, redirect, dismiss]);

  log(errors);

  return (
    <>
      {unrecoverable && <Redirect to={{ pathname: '/unrecoverable_error' }} />}
      {redirect && <Redirect to={{ pathname: redirect.routeId }} />}
      {children}
      {errors[0] && <ErrorToast error={errors[0]} />}
    </>
  );
};

ErrorHandler.propTypes = {
  dismiss: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([
      UnrecoverableErrorModel,
      ToastErrorModel,
      RedirectErrorModel,
    ])
  ),
  redirect: RedirectErrorModel,
  unrecoverable: PropTypes.object,
};

export default ErrorHandler;
