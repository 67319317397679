import Actions from './actions';
import STORAGE_KEY from './storage.key';

const initialState = {};

const next = (state, key = STORAGE_KEY) => {
  localStorage.setItem(
    key,
    JSON.stringify({ ...state, lastUpdated: Date.now() })
  );
  return state;
};

const hydrate = (expires, key = STORAGE_KEY) => {
  const local = localStorage.getItem(key);
  const fromStorage = local ? JSON.parse(local) : {};
  const { lastUpdated, data } = fromStorage;

  // this has the effect of "refreshing" the session timeout limit
  // as given by the lastUpdated key in localStorage on every page load
  // we can optionally extend the session whenever we want by dispatching EXTEND
  // or using call the function returned from the useExtendSession() hook
  return next(
    {
      expires,
      data: new Date(lastUpdated + expires) >= Date.now() ? data : {},
    },
    key
  );
};

const session = (state = initialState, action) => {
  switch (action.type) {
    case Actions.INIT:
      return hydrate(action.payload);

    case Actions.SET_ITEM:
      return next({
        ...state,
        data: {
          ...state.data,
          [action.payload.key]: action.payload.value,
        },
      });

    case Actions.REMOVE_ITEM:
      if (typeof state.data[action.payload] !== 'undefined') {
        return next({
          ...state,
          data: Object.entries(state.data).reduce(
            (acc, [key, value]) =>
              key === action.payload ? acc : { ...acc, [key]: value },
            {}
          ),
        });
      }
      return state;

    case Actions.EXTEND:
      return next(state);

    case Actions.CLEAR:
      return next({
        ...state,
        data: {},
      });

    default:
      return state;
  }
};

export default session;
