import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const MetaTagInjector = ({ metaTags }) => {
  if (metaTags && metaTags.length) {
    var metaTagList = [];
    metaTags.forEach(tag => {
      metaTagList.push(<meta name={tag.name} content={tag.content} />);
    });
    return <Helmet>{metaTagList}</Helmet>;
  }
  return null;
};

MetaTagInjector.propTypes = {
  metaTags: PropTypes.array,
};

export default MetaTagInjector;
