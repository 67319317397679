/**
 * Routes from Paths
 *
 * @param {paths} - object of paths
 * @param {intl} - intl object
 * @param {activatedRouteId} - the current activatedRouteId
 * @param {currentPath} - the current path name
 */
export const routesFromPaths = (paths, intl, activatedRouteId, currentPath) =>
  Object.entries(paths).map(([routeId, descriptor]) => {
    const { pathLocaleId: id } = descriptor;
    const localizedUrl = `${id ? intl.formatMessage({ id }) : routeId}`;
    return {
      ...descriptor,
      routeId,
      localizedUrl: `/${intl.locale}${localizedUrl}`,
      path: [
        routeId,
        `/:locale([a-z]{2})${routeId}`,
        `/:locale([a-z]{2})${localizedUrl}`,
        // also match the current location if this is the currently activated route
        // this is critical so that /fr/erreur can match /error
        activatedRouteId === routeId ? currentPath : undefined,
      ].filter(f => !!f),
    };
  });
