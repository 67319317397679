import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const CSS = ({ content }) => (
  <Helmet>
    <style>{content}</style>
  </Helmet>
);

CSS.propTypes = {
  content: PropTypes.string.isRequired,
};

CSS.defaultProps = {
  content: '',
};

export default CSS;
