import { createActionName } from 'utils/actions.util';

class Actions {
  static prefix = '[SESSION]';

  static createActionName = action => createActionName(Actions.prefix, action);

  static EXTEND = Actions.createActionName('EXTEND');

  static SET_ITEM = Actions.createActionName('SET_ITEM');

  static REMOVE_ITEM = Actions.createActionName('REMOVE_ITEM');

  static CLEAR = Actions.createActionName('CLEAR');

  static INIT = Actions.createActionName('INIT');

  static extend = () => ({
    type: Actions.EXTEND,
  });

  // expires is a number in seconds
  static init = expires => ({
    type: Actions.INIT,
    payload: expires * 1000,
  });

  static setItem = (key, value) => ({
    type: Actions.SET_ITEM,
    payload: { key, value },
  });

  static removeItem = key => ({ type: Actions.REMOVE_ITEM, payload: key });

  static clear = () => ({ type: Actions.CLEAR });
}

export default Actions;
