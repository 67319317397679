import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/styles/useTheme';
import merge from 'utils/merge.util';

const bps = ['xs', 'sm', 'md', 'lg', 'xl'];
const mediaQuery = (bp, theme) =>
  bps.includes(bp) ? theme.breakpoints.up(bp) : bp;

// at a given media query/breakpoint
// 1. properties are shallow merged
// 2. themes and styles are deep merged
// 3. children are replaced
// 4. conditions are replaced
const doMerge = (
  { properties, styles, children, theme, conditions },
  newProperties,
  newConditions,
  newStyles,
  newTheme,
  newChildren
) => ({
  properties: {
    ...(properties || {}),
    ...(newProperties || {}),
  },
  conditions: newConditions || conditions,
  theme: theme ? merge(theme, newTheme || {}) : newTheme,
  styles: styles ? merge(styles, newStyles || {}) : newStyles,
  children: newChildren || children,
});

// hook factory that when run returns the appropriate configuration based on media object
// media is an object that has breakpoints/media-queries for keys and component descriptors for values
// when a given media query is matched the original component descriptor is overridden (by the rules above)
const makeConfiguration = ({
  children = [],
  theme,
  styles,
  properties,
  media,
  conditions,
}) => () => {
  // get the existing theme and merge it with the one from the base configuration descriptor
  // in case the breakpoints need to be used in useMediaQuery
  const existingTheme = useTheme();
  const mergedTheme = theme ? merge(existingTheme, theme) : existingTheme;

  // for each entry in the media descriptor call useMediaQuery hook
  // that applies any applicable overrides
  const {
    properties: overrideProperties,
    styles: overrideStyles,
    children: overrideChildren,
    theme: overrideTheme,
    conditions: overrideConditions,
  } = Object.entries(media || {}).reduce(
    (acc, [mq, { properties, styles, children, theme, conditions }]) => {
      const matchesMedia = useMediaQuery(mediaQuery(mq, mergedTheme));
      return matchesMedia
        ? doMerge(acc, properties, conditions, styles, theme, children)
        : acc;
    },
    { properties, styles, children, conditions, theme }
  );

  return {
    properties: overrideProperties,
    conditions: overrideConditions,
    styles: overrideStyles,
    children: overrideChildren || [],
    theme: overrideTheme ? merge(mergedTheme, overrideTheme) : null,
  };
};

export default makeConfiguration;
