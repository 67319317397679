// middleware, listener, and INIT_ACTION are exported
// for testing purposes only
// PLEASE ONLY USE THE DEFAULT EXPORT!!!!
export const MICRO_INIT_ACTION_TYPE = 'INIT_STI_MICRO';

export const middleware = getOrigin => () => next => action => {
  if (getOrigin()) window.parent.postMessage(action, getOrigin());
  return next(action);
};

export const listener = (dispatch, getOrigin, setOrigin) => event => {
  const action = event.data;
  const origin = getOrigin();

  if (action.type === MICRO_INIT_ACTION_TYPE) {
    if (origin && origin !== event.origin)
      throw new Error(`origin already set!`);
    setOrigin(event.origin);
    // dispatch(action);
    return;
  }

  if (!origin || event.origin !== origin) return;
  dispatch(action);
};

// the show
export default dispatch => {
  let origin;
  return (
    window.addEventListener(
      'message',
      listener(
        dispatch,
        () => origin,
        o => (origin = o)
      )
    ) || middleware(() => origin)
  );
};
