const cleanUp = loaderContainer => () => {
  loaderContainer &&
    loaderContainer.parentNode &&
    loaderContainer.parentNode.removeChild(loaderContainer);
};

const fadeOutAll = (loader, loaderContainer) => () => {
  loader.removeEventListener('animationend', fadeOutAll);
  loader.classList.add('loader-fadeOut');
  loader.addEventListener('animationend', cleanUp(loaderContainer));
  if (loaderContainer)
    loaderContainer.classList.add('loader-container-fadeOut');
};

export const completeLoading = () => {
  let loadIndicator = document.getElementById('loading-indicator');
  if (loadIndicator) loadIndicator.parentNode.removeChild(loadIndicator);
  let elementExists = document.getElementById('sti-loader-container');
  if (elementExists) {
    let loader = document.getElementById('sti-loader');
    let loaderContainer = document.getElementById('sti-loader-container');
    loader.classList.add('loader-loaded');
    loader.addEventListener(
      'animationend',
      fadeOutAll(loader, loaderContainer)
    );
  }
};
