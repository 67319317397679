import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import { ToastErrorModel } from '../models';

// TO DO: Toast Error Message - dismissable, etc
const ErrorToast = ({ error }) => {
  return <Snackbar message={error.message} />;
};

ErrorToast.propTypes = {
  error: PropTypes.oneOfType([ToastErrorModel]),
};

export default ErrorToast;
