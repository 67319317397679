import { connect } from 'react-redux';
import LocalesProvider from './LocalesProvider.component';
import * as configSelector from 'domains/core/config/selectors';

// Register locales domain
import * as locales from 'domains/core/locales';

export const mapStateToProps = state => ({
  forceLocale: configSelector.forceLocale(state),
  dynamicMessages: locales.selectors.mergedDynamicMessages(state),
  supportedLanguages: configSelector.supportedLanguages(state),
  defaultLocale: configSelector.defaultLocale(state),
  localeMessages: configSelector.localeMessages(state),
  selectedLocale: locales.selectors.selectedLocale(state),
});

export const mapDispatchToProps = dispatch => ({
  selectLocale: locale => dispatch(locales.Actions.selectLocale(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocalesProvider);
