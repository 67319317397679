import { createActionName } from 'utils/actions.util';

class Actions {
  prefix = '[LOCALES]';

  SELECT_LOCALE = createActionName(this.prefix, 'SELECT_LOCALE');

  UPDATE_ACTIVATED_ROUTE_ID = createActionName(
    this.prefix,
    'UPDATE_ACTIVATED_ROUTE'
  );

  LOAD_DYNAMIC_MESSAGES = createActionName(
    this.prefix,
    'LOAD_DYNAMIC_MESSAGES'
  );

  selectLocale = selectedLocale => ({
    type: this.SELECT_LOCALE,
    selectedLocale,
  });

  updateActivatedRouteId = routeId => ({
    type: this.UPDATE_ACTIVATED_ROUTE_ID,
    routeId,
  });

  loadDynamicMessages = (key, messages) => ({
    type: this.LOAD_DYNAMIC_MESSAGES,
    payload: { key, messages },
  });
}

export default new Actions();
