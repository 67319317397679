import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';

export const getNextLocale = (supportedLanguages, selectedLocale) => {
  let index = supportedLanguages.findIndex(item => item === selectedLocale);
  index += 1;

  if (index >= supportedLanguages.length) {
    index = 0;
  }
  return supportedLanguages[index];
};

const Component = ({ selectedLocale, supportedLanguages, switchLanguages }) => {
  const nextLocale = getNextLocale(supportedLanguages, selectedLocale);

  if (supportedLanguages && supportedLanguages.length >= 2) {
    return (
      <Button
        variant={'text'}
        isPrimary={false}
        data-cmp="LocaleSwitcher"
        text={`localeSwitcher_button_label_${selectedLocale}`}
        onClick={() => {
          switchLanguages(nextLocale);
          document.documentElement.lang = nextLocale;
        }}
      />
    );
  } else {
    return null;
  }
};

Component.propTypes = {
  selectedLocale: PropTypes.string.isRequired,
  supportedLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  switchLanguages: PropTypes.func.isRequired,
};

export default Component;
