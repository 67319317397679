import { createAPIService } from './api.service';
import { config } from 'api';

export const REFERENCE_ENDPOINT = (type, pageItems, page) =>
  `/search?what=${type}&pageItems=${pageItems}&page=${page}`;
const service = createAPIService('/reference/data/api');
const SEARCH_TYPE = 'ref-data-physician';
const SEARCH_ITEMS_PER_PAGE = 50;
const SEARCH_PAGE = 0;
const MIN_CHARACTERS = 2;
// endpoints
service.getPhysicians = async params => {
  const response = await service.get(
    REFERENCE_ENDPOINT(SEARCH_TYPE, SEARCH_ITEMS_PER_PAGE, SEARCH_PAGE),
    {
      params: params,
    }
  );
  return response.data;
};

service.getPrivateInsuranceProviders = async params => {
  if (params.query.length > MIN_CHARACTERS) {
    const appConfiguration = await config.getApplicationConfiguration();
    const programId =
      appConfiguration.consumer.components.PROGRAM_LOADER.programId;
    const response = await service.get(
      `/v1/private-insurance-providers?programId=${programId}`
    );
    return response.data;
  }
};

export default service;
