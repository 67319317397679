import { formError } from './selectors';
import { createActionName } from 'utils/actions.util';
class Actions {
  static prefix = '[ERROR]';

  static createActionName = action => createActionName(Actions.prefix, action);

  static ADD = Actions.createActionName('ADD');

  static DISMISS = Actions.createActionName('DISMISS');

  static UNRECOVERABLE = Actions.createActionName('UNRECOVERABLE');

  static REDIRECT = Actions.createActionName('REDIRECT');

  static FORM = Actions.createActionName('FORM');

  static add = payload => ({ type: Actions.ADD, payload });

  static dismiss = payload => ({ type: Actions.DISMISS, payload });

  static redirect = routeId => ({ type: Actions.REDIRECT, routeId });

  static dismissFormError = id => (dispatch, getState) => {
    const err = formError(id)(getState());
    if (err) dispatch(Actions.dismiss(err));
  };

  static addToast = error => ({
    type: Actions.ADD,
    error,
  });

  static unrecoverable = error => ({
    type: Actions.UNRECOVERABLE,
    error,
  });

  static formError = (formId, error) => ({
    type: Actions.FORM,
    formId,
    error,
  });
}

export default Actions;
