import Domain from './name';
import reducer from './reducer';
import Actions from './actions';
import * as selectors from './selectors';
import { ErrorHandler } from './components';
import * as hooks from './hooks';
import { reducerRegistry } from 'store';

reducerRegistry.register(Domain, reducer);

// make these actions accessible from anywhere
export const AddUnrecoverableError = Actions.addUnrecoverable;
export const AddToastError = Actions.addToast;

// public api
export { ErrorHandler, Actions, selectors, hooks };
