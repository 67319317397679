import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import useRemoteScripts from './useRemoteScripts';
import env from 'runtime.env';

const Component = ({ path, isS3, scripts }) => {
  useRemoteScripts(scripts);
  return (
    <Helmet>
      <link
        rel="icon"
        type="image/png"
        href={isS3 ? `${env.RT_S3_ASSETS}${path}` : path}
        sizes="16x16"
      />
    </Helmet>
  );
};

Component.propTypes = {
  isS3: PropTypes.bool,
  path: PropTypes.string,
  scripts: PropTypes.array,
};

Component.defaultProps = {
  isS3: false,
};

export default Component;
