import Bugsnag from '@bugsnag/js';

const RETRIES = 3;

const delay = async (fn, by) => {
  await new Promise(res => setTimeout(() => res(), by));
  return fn();
};

export const importComponent = descriptor => async (
  importer = module => import(`modules/${module}`),
  retries = RETRIES
) => {
  // by default use the common module if none is specified
  const moduleName = descriptor.module || 'common';
  let module;
  try {
    module = await importer(moduleName);
  } catch (err) {
    if (retries)
      // try to load this chunk again after 100ms
      return delay(() => importComponent(descriptor)(importer, --retries), 100);
    // eslint-disable-next-line no-console
    console.error(descriptor);
    Bugsnag.notify(err);
    throw new Error(
      `Component Loader: Failed to load component ${descriptor.componentName} from invalid or missing module ${moduleName}`
    );
  }

  const component = module[descriptor.componentName];
  if (!component) {
    // eslint-disable-next-line no-console
    console.error(descriptor);
    throw new Error(
      `Component Loader: Module ${moduleName} does not contain ${descriptor.componentName}`
    );
  }

  return { default: component };
};
