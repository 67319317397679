import merge from 'deepmerge';
import { config } from 'api';
import { createActionName } from 'utils/actions.util';
import defaultConfig from 'defaults/defaultConfig';
import { configMergeOptions as options } from 'utils/merge.util';

class Actions {
  static prefix = '[CONFIG]';

  static createActionName = action => createActionName(Actions.prefix, action);

  static SET = Actions.createActionName('SET');

  static GET = Actions.createActionName('GET');

  static set = payload => ({ type: Actions.SET, payload });

  static get = () => ({ type: Actions.GET });

  static fetch = (
    method = config.getApplicationConfiguration,
    params
  ) => async dispatch => {
    try {
      dispatch(Actions.get());
      const configResult = await method(params ? params : null);
      const mergedConfig = merge(defaultConfig, configResult, options);
      dispatch(Actions.set(mergedConfig));
    } catch (e) {
      dispatch(Actions.set(defaultConfig));
    }
  };
}

export default Actions;
