import PropTypes from 'prop-types';

export const REFERER = 'REFERER';
export const DIRECT = 'DIRECT';
export const LIKE = 'LIKE';
export const NOTLIKE = 'NOTLIKE';
export const SESSION = 'SESSION';

export const MessageModel = PropTypes.shape({
  [PropTypes.string]: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
});

export const LocalesModel = PropTypes.shape({
  defaultLocale: PropTypes.string.isRequired,
  placeholders: PropTypes.shape({
    s3_path: PropTypes.string.isRequired,
  }).isRequired,
  messages: MessageModel,
  supportedLanguages: PropTypes.arrayOf(PropTypes.string),
});

export const ThemeModel = PropTypes.object;
export const StyleModel = PropTypes.object;

export const ComponentModel = PropTypes.shape({
  componentName: PropTypes.string.isRequired,
  children: PropTypes.array,
  gtmTag: PropTypes.string,
  module: PropTypes.string,
  properties: PropTypes.object,
  style: StyleModel,
  theme: ThemeModel,
});

const RouteGuardModel = {
  type: PropTypes.oneOf([REFERER, DIRECT, SESSION]),
  redirect: PropTypes.string,
  data: PropTypes.object,
};

export const RefererGuardModel = PropTypes.shape({
  ...RouteGuardModel,
  how: PropTypes.oneOf([LIKE, NOTLIKE]),
  match: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]),
});

export const SessionGuardModel = PropTypes.shape({
  ...RouteGuardModel,
  how: PropTypes.oneOf(['some', 'every']),
  when: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
});

export const DirectGuardModel = PropTypes.shape({
  ...RouteGuardModel,
});

export const ComponentsModel = PropTypes.shape({
  [PropTypes.string]: ComponentModel,
});

export const RouteModel = PropTypes.shape({
  paths: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      componentName: PropTypes.string.isRequired,
      data: PropTypes.object,
      default: PropTypes.bool,
      guard: PropTypes.arrayOf(
        PropTypes.oneOfType([RefererGuardModel, DirectGuardModel])
      ),
      pathLocaleId: PropTypes.string,
    }),
  }).isRequired,
  default: PropTypes.string,
});

const ConfigShape = {
  components: ComponentModel.isRequired,
  locales: LocalesModel.isRequired,
  routes: RouteModel.isRequired,
  theme: ThemeModel,
};

ConfigShape.overrides = PropTypes.shape({
  [PropTypes.string]: PropTypes.shape(ConfigShape),
});

export const ConfigModel = PropTypes.shape(ConfigShape);
