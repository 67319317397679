import axios from 'axios';
import env from 'runtime.env';

export const baseURL = process.env.NODE_ENV === 'development' ? '' : env.RT_API;

const base = {
  // if in development mode, requests are being proxied through localhost, so we don't need a baseURL
  baseURL,
  headers: {
    'content-type': 'application/json',
    accept: 'application/json',
    'access-control-allow-origin': '*',
    'access-control-allow-headers': 'open',
  },
};

export const createAPIService = (service, config = {}) => {
  const api = axios.create({
    ...base,
    ...config,
    headers: {
      ...base.headers,
      ...(config.headers || {}),
    },
  });

  api.interceptors.request.use(config => ({
    ...config,
    url: `${service}${config.url}`,
  }));

  return api;
};
