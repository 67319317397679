import React, { useState, useEffect } from 'react';
import { KeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import model from './Keycloak.model';
import KeycloakLoader from './KeycloakLoader.component';
import { children } from 'common/models';
import env from 'runtime.env';

/**
 * Component Configuration
 *  This component accepts all configuration items that can be passed to the js adapter
 *  https://github.com/keycloak/keycloak-documentation/blob/master/securing_apps/topics/oidc/javascript-adapter.adoc
 *
 *  In general, the following is enough configuration to get it to work
 * "KEYCLOAK": {
 *  "properties": {
 *    "init": { "onLoad": "login-required" }, // see check-sso for a better experience https://www.keycloak.org/docs/latest/securing_apps/#_javascript_adapter
 *    "clientId": string
 *    "realm": string
 *    "url?": provided through env by default, can be overridden through configuration, unlikely to ever want this feature
 *  }
 * }
 *
 */

const KeycloakComponent = ({ children, init, ...keycloakProps }) => {
  const [keycloak, setKeycloak] = useState();

  useEffect(() => {
    if (init) {
      const keycloak = new Keycloak({
        url: env.RT_KEYCLOAK_URL,
        ...keycloakProps,
      });
      keycloak.onAuthRefreshError = () => keycloak.logout();
      keycloak.onTokenExpired = () => keycloak.updateToken();
      setKeycloak(keycloak);
    }
    // eslint-disable-next-line
  }, [init]);

  return keycloak ? (
    <KeycloakProvider keycloak={keycloak} initConfig={init}>
      <KeycloakLoader>{children}</KeycloakLoader>
    </KeycloakProvider>
  ) : (
    <> {children} </>
  );
};

KeycloakComponent.propTypes = {
  children,
  ...model,
};

export default KeycloakComponent;
