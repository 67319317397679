export default () => ({
  loader: () => ({
    position: 'fixed',
    display: 'flex',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  }),
});
