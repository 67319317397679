/* eslint-disable */
import apiMessages from './messages/api';
import env from 'runtime.env';

export default {
  consumer: {
    noIndex: false,
    metaTags: [{
      name: '',
      content: ''
    }],
    routes: {
      paths: {
        '/unrecoverable_error': {
          componentName: 'UNRECOVERABLE',
          pathLocaleId: 'route_unrecoverable_error',
          exact: true,
          guard: [
            {
              type: 'DIRECT',
            },
          ],
        },
      },
    },
    theme: {
      breakpoints: {
        values: {
          xs: 0,
          sm: 375,
          md: 768,
          lg: 1024,
          xl: 1920,
        },
      },
      palette: {
        error: {
          main: '#ff0000',
        },
        warning: {
          main: '#ffa21a',
        },
        success: {
          main: '#00cc00',
        },
        background: {
          default: '#ffffff',
        },
        text: {
          primary: '#373A40',
        },
        type: 'light',
        action: {
          disabled: '#ffffff',
          disabledBackground: '#D4DADF',
          selected: '#01618E',
        },
        primary: {
          main: '#01618E',
        },
        brand: {
          main: '#008ED4',
        },
        link: {
          main: '#0070BD',
        },
      },
      typography: {
        htmlFontSize: 10,
        useNextVariants: true,
        fontFamily: 'Proxima Nova',
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
          fontWeight: 600,
          fontSize: '3.4rem',
          lineHeight: '4.1rem',
        },
        h4: {
          fontWeight: 600,
          fontSize: '2rem',
          lineHeight: '3rem',
        },
        h5: {
          fontWeight: 600,
          fontSize: '2rem',
          lineHeight: '2.4rem',
        },
        h6: {
          fontWeight: 600,
          fontSize: '1.6rem',
          lineHeight: '2.3rem',
        },
        subtitle1: {
          fontSize: '1.2rem',
          lineHeight: '1.4rem',
        },
        subtitle2: {
          fontSize: '1.2rem',
          fontWeight: '400',
          lineHeight: '1.5rem',
        },
        body1: {
          fontSize: '1.6rem',
          fontWeight: '400',
          lineHeight: '2.2rem',
        },
        button: {
          fontWeight: '600',
          lineHeight: '1.2rem',
        },
      },
    },
    localesConfiguration: {
      supportedLanguages: ['en', 'fr'],
      defaultLocale: 'en',
      placeholders: {
        s3_path: `${env.RT_S3_ASSETS}`,
      },
      messages: {
        ...apiMessages,
        ecard_button_label: {
          en: 'Download your card here',
          fr: 'Téléchargez votre carte ici',
        },
        footer_sti_copyright: {
          en:
            "©{currentYear} STI Technologies Limited.<br/>All rights reserved. <a target='_blank' href='https://smartsti.com/privacy'>Privacy Policy</a><br/>",
          fr:
            "©{currentYear} STI Technologies Limited.<br/>Tous droits réservés. <a target='_blank' href='https://smartsti.com/fr/privacy'>Politique de confidentialité</a><br/>",
        },
        localeSwitcher_button_label_en: {
          en: 'Français',
          fr: 'Français',
        },
        localeSwitcher_button_label_fr: {
          en: 'English',
          fr: 'English',
        },
        footer_sti_light_logo_url: {
          en: '/sti/assets/sti_logo.png',
          fr: '/sti/assets/sti_logo.png',
        },
        footer_sti_dark_logo_url: {
          en: '/sti/assets/sti_logo_dark.png',
          fr: '/sti/assets/sti_logo_dark.png',
        },
        footer_sti_logo_alt: {
          en: 'logo image',
          fr: '[FR] logo image',
        },
        components_configDialog_dialog_title: {
          en: '{title} - Configuration',
          fr: '[FR] {title} - Configuration',
        },
        route_unrecoverable_error: {
          en: '/unrecoverable_error',
          fr: '/erreur_irrécupérable',
        },
        form_submit_button: {
          en: 'SUBMIT',
          fr: 'SOUMETTRE',
        },
        form_reset_button: {
          en: 'RESET',
          fr: 'RÉINITIALISER',
        },
        form_fields_required: {
          en: '<sup>*</sup> Are required fields',
          fr: '<sup>*</sup> Champ obligatoire',
        },
        dialog_dismiss_button_label: {
          en: 'CLOSE',
          fr: 'FERMER',
        },
        form_fields_autocomplete_not_found: {
          en: 'no result for',
          fr: 'aucun résultat pour',
        },
        form_fields_autocomplete_service_not_available: {
          en: 'service unavailable',
          fr: 'Service indisponible',
        },
      },
    },
    components: {
      UNRECOVERABLE: {
        componentName: 'ErrorPage',
      },
    },
  },
};
