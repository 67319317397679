import { connect } from 'react-redux';
import Actions from '../../actions';
import * as selectors from '../../selectors';
import ConfigProvider from './ConfigProvider.component';

const mapStateToProps = state => ({
  loading: selectors.loading(state),
  config: selectors.config(state),
  theme: selectors.themeConfiguration(state),
});

const mapDispatchToProps = { getConfig: Actions.fetch };

export default connect(mapStateToProps, mapDispatchToProps)(ConfigProvider);
