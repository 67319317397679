// Helper function to get browser supported language
export const getBrowserLocale = (supportedLanguages, defaultLocale) => {
  // IE11 doesn't fully support window.navigator,
  // Ref: https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
  // Which will cause crashing on IE11. So here we just check if
  // window.navigator is available.
  const locale =
    (window.navigator.languages &&
      window.navigator.languages.find(item =>
        supportedLanguages.includes(item)
      )) ||
    defaultLocale;
  return locale;
};

// Helper function to merge default locales with config locales
export const getMessagesFromConfig = (localeMessages, locale) => {
  const messages = Object.keys(localeMessages).reduce((acc, key) => {
    acc[key] = localeMessages[key][locale] || key;
    return acc;
  }, {});
  return messages;
};

export const localeFromUrl = (path, supportedLanguages) =>
  supportedLanguages.find(lang => lang === path.split('/')[1]);

export const getLocale = ({ supportedLanguages, forceLocale, defaultLocale }) =>
  localeFromUrl(window.location.pathname, supportedLanguages) ||
  forceLocale ||
  getBrowserLocale(supportedLanguages, defaultLocale);
