import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import { completeLoading } from 'utils/loaderHelper';

const ConfigProvider = ({ loading, getConfig, config, theme, children }) => {
  if (!loading && !config) {
    getConfig();
  }

  if (!loading && config) {
    completeLoading();
  }

  return config ? (
    <ThemeProvider theme={createMuiTheme(theme)}>{children}</ThemeProvider>
  ) : null;
};

ConfigProvider.propTypes = {
  config: PropTypes.object,
  getConfig: PropTypes.func,
  loading: PropTypes.bool,
  theme: PropTypes.object,
};

export default ConfigProvider;
