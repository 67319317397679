import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import {
  ContextConditionalComponent,
  model,
} from 'components/ContextConditional/index';
import { children } from 'common/models';

// allows us to parse components out of nested structures
// as long as the component knows it's own API, then this can
// be an invaluable mechanism of keeping configurations simple
/*
"properties": {
  "propA": "value",
  "propB": {
    "someKeyInPropB1": "value",
    "someKeyInPropB2": {
      "someKeyInPropB21": "value",
      "someKeyInPropB22": {
        "type": "component",
        "component": IDENTIFIER_OR_DESCRIPTOR
      }
    },
    "someKeyInPropB3": [
      1,
      2,
      {
        "type":"component",
        "component": IDENTIFIER_OR_DESCRIPTOR
      },
      3,
      4
    ]
  }
}
*/
const recursivelyParseForComponents = (what, loader) => {
  if (
    !what ||
    typeof what === 'string' ||
    typeof what === 'number' ||
    typeof what === 'boolean'
  ) {
    return what;
  }

  if (Array.isArray(what)) {
    return what.map(w => recursivelyParseForComponents(w, loader));
  }

  // special case - if a property is an object with a "type" key whose value is "component"
  // then it will also have a "component" key which could be either an identifier
  // or descriptor and will need to be evaluated and loaded by loadComponent
  return what.type === 'component'
    ? loader(what.component)
    : Object.entries(what).reduce(
        (carry, [key, value]) => ({
          ...carry,
          [key]: recursivelyParseForComponents(value, loader),
        }),
        {}
      );
};

const parseProps = (properties, loader) =>
  recursivelyParseForComponents(properties, loader);

const RenderConfiguredComponent = ({
  ComponentINTERNAL,
  useStylesINTERNAL,
  children,
  conditionsINTERNAL,
  ...props
}) => {
  const classes = useStylesINTERNAL();
  const cmp = (
    <ComponentINTERNAL {...props} classes={classes}>
      {children}
    </ComponentINTERNAL>
  );

  return conditionsINTERNAL ? (
    <ContextConditionalComponent {...conditionsINTERNAL}>
      {cmp}
    </ContextConditionalComponent>
  ) : (
    cmp
  );
};

RenderConfiguredComponent.propTypes = {
  ComponentINTERNAL: PropTypes.object.isRequired,
  useStylesINTERNAL: PropTypes.func.isRequired,
  children,
  conditionsINTERNAL: PropTypes.shape(model),
};

const ConfiguredComponent = ({
  useConfiguration,
  Component,
  loader,
  children: unconfiguredChildren,
}) => {
  const {
    properties,
    styles,
    theme,
    conditions,
    children: configuredChildren,
  } = useConfiguration();
  // if the user has supplied styles, use them
  const useStyles = styles ? makeStyles(styles) : () => null;
  const props = parseProps(properties, loader);
  // sometimes we may use our ConfiguredComponent to render "real" react children
  const children =
    unconfiguredChildren ||
    configuredChildren.map(c =>
      loader(c.type === 'component' ? c.component : c)
    );
  const configured = (
    <RenderConfiguredComponent
      {...props}
      conditionsINTERNAL={conditions}
      useStylesINTERNAL={useStyles}
      ComponentINTERNAL={Component}
    >
      {children}
    </RenderConfiguredComponent>
  );

  // if a theme was retrieved from the useConfiguration hook, wrap our component in a ThemeProvider
  return theme ? (
    <ThemeProvider theme={theme}>{configured}</ThemeProvider>
  ) : (
    configured
  );
};

ConfiguredComponent.propTypes = {
  loader: PropTypes.func.isRequired,
  useConfiguration: PropTypes.func.isRequired,
  Component: PropTypes.any,
};

export default ConfiguredComponent;
