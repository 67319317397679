import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, matchPath } from 'react-router-dom';
import ConfiguredComponentLoader from '../../ConfiguredComponentLoader';
import { RefererGuardModel } from '../../../models';
import useGuard from './useGuard';
import { useRouter } from 'utils/router';

const ConfiguredRoute = ({
  guard,
  routeId,
  componentName,
  localizedUrl,
  updateActivatedRouteId,
}) => {
  const { location } = useRouter();
  const allowed = useGuard(guard);

  if (!allowed) {
    return (
      <Redirect
        to={{
          pathname: guard.redirect || '/',
          state: { ...(guard.data || {}) },
        }}
      />
    );
  }

  updateActivatedRouteId(routeId);

  return (
    <>
      {matchPath(location.pathname, localizedUrl) && (
        <Redirect push={false} to={localizedUrl} />
      )}
      <ConfiguredComponentLoader id={componentName} />
    </>
  );
};

ConfiguredRoute.propTypes = {
  componentName: PropTypes.string.isRequired,
  localizedUrl: PropTypes.string.isRequired,
  updateActivatedRouteId: PropTypes.func.isRequired,
  guard: PropTypes.arrayOf(PropTypes.oneOfType([RefererGuardModel])),
  routeId: PropTypes.string,
};

export default ConfiguredRoute;
