import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import styles from './KeycloakLoader.styles';
import { setAPIToken, setAPIHeaders } from 'api/tokens';

const useStyles = makeStyles(styles);

const KeycloakLoader = ({ children }) => {
  const classes = useStyles();
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );
  }

  if (!keycloak.authenticated) {
    return null;
  }

  setAPIToken(keycloak.token);
  setAPIHeaders({ AuthContext: keycloak.realm });

  return children;
};

KeycloakLoader.propTypes = {};

export default KeycloakLoader;
