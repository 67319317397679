import Domain from './name';
import reducer from './locales.reducer';
import * as selectors from './selectors';
import * as hooks from './hooks';
import * as models from './locales.model';
import Actions from './locales.actions';
import { LocalesProvider, LocalesSwitcher } from './components';
import { reducerRegistry } from 'store';

reducerRegistry.register(Domain, reducer, { preventClear: true });

export { selectors, Actions, models, LocalesProvider, LocalesSwitcher, hooks };
