import Domain from './name';
import reducer from './reducer';
import Actions from './actions';
import * as selectors from './selectors';
import {
  useSession,
  useInitSession,
  useSetSessionItem,
  useRemoveSessionItem,
  useClearSession,
  useExtendSession,
} from './hooks';
import { SetSessionData, ClearSessionData } from './components';
import { reducerRegistry } from 'store';

reducerRegistry.register(Domain, reducer);

// make these actions accessible from anywhere
// these actions will primarily be used inside other actions/class based components
// functional components will use the action hooks
export const remove = Actions.removeItem;
export const clear = Actions.clear;
export const clearExpired = Actions.clearExpired;

export {
  useSession,
  useInitSession,
  selectors,
  useSetSessionItem,
  useRemoveSessionItem,
  useClearSession,
  useExtendSession,
  SetSessionData,
  ClearSessionData,
};
