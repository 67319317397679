import { connect } from 'react-redux';
import {
  themeConfiguration,
  routesConfiguration,
  faviconConfiguration,
  scriptConfiguration,
  keycloakConfiguration,
  mainConfiguration,
  noIndex,
  metaTags,
} from '../../selectors';
import MainComponent from './Main.component';

import ErrorActions from 'domains/core/error/actions';

export const mapStateToProps = state => ({
  main: mainConfiguration(state),
  theme: themeConfiguration(state),
  routes: routesConfiguration(state),
  favicon: faviconConfiguration(state),
  scripts: scriptConfiguration(state),
  keycloak: keycloakConfiguration(state),
  noIndex: noIndex(state),
  metaTags: metaTags(state),
});

export const mapDispatchToProps = {
  unrecoverable: e => ErrorActions.unrecoverable(e),
};

export default connect(mapStateToProps, mapDispatchToProps)(MainComponent);
