import React from 'react';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@material-ui/styles/makeStyles';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import styles from './Button.styles';
import defaultProps from './Button.config';
import ButtonPropTypes from './Button.model';

const useStyles = makeStyles(styles);

export const Component = props => {
  const {
    isPrimary,
    color,
    text,
    fullWidth,
    size,
    href,
    disabled,
    link,
    script,
    onClick,
    ...rest
  } = props;

  const history = useHistory();
  const classes = useStyles({ size, ...rest });

  const clickHandler = (...args) => {
    if (script) {
      try {
        // eslint-disable-next-line
        eval(script);
      } catch (e) {
        console.log('Failed to execute click script', e);
      }
    }
    if (link) {
      history.push(link);
    }
    if (onClick) {
      onClick(...args);
    }
  };

  return (
    <Button
      {...rest}
      onClick={clickHandler}
      color={color}
      variant={isPrimary ? 'contained' : 'text'}
      className={isPrimary ? classes.primaryBtn : classes.secondaryBtn}
      disabled={disabled}
      fullWidth={fullWidth}
      size={size}
      href={href}
      data-cmp="Button"
    >
      <FormattedMessage id={text} />
    </Button>
  );
};

Component.propTypes = ButtonPropTypes;

Component.defaultProps = defaultProps;

export default Component;
