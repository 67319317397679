import { createBrowserHistory } from 'history';
import { useState, useEffect } from 'react';

// https://github.com/ReactTraining/react-router/blob/master/FAQ.md#how-do-i-access-the-history-object-outside-of-components
// in order to use REFERER and DIRECT route guards, we need to be able to access history
// simple useHistoryHook that keeps track of thes stack
const browserHistory = createBrowserHistory();
const globalHistory = [];
browserHistory.listen(l => globalHistory.push(l));

const useHistory = () => {
  const [history, setHistory] = useState([...globalHistory]);
  useEffect(() => browserHistory.listen(l => setHistory([...history, l])));
  return history;
};

export { useHistory };
export default browserHistory;
